@import "../../shared";

.Footer {
  text-align: center;
  padding: 2rem;

  img {
    margin: auto;
    width: 10%;
    max-width: 160px;
  }
}
