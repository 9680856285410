$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-wide: 1440px;

$radius: 0.4em;

// Colours
$text: #07585a;
$highlight: pink;
$link: pink;

%no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

%text-content {
  padding: 0 1em;
  font-size: 80%;
}

// Fonts
$font: inherit, serif;
$other-font: "Sedan", serif;
$header-font: "MedievalSharp", cursive;

// A raised button
%button {
  transition: box-shadow 0.2s ease-out; //, background 0.8s ease-out;
  box-shadow: #999 0.1em 0.3em 0.4em;

  &:hover {
    box-shadow: #999 0.1em 0.1em 0.1em;
  }
}
