@import "../../shared";

.Header {
  text-align: center;

  .Hero {
    margin: auto;
    z-index: 5;
    border: solid 2px black;
    border-bottom-width: 8px;
  }

  .Knot {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    margin-left: -50%;
    text-align: center;

    img {
      margin: auto;
    }
  }

  .Gradient {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 10rem;
    background: linear-gradient(90deg, #32650a 0%, #062904 35%, #0e2907 100%);

    @media (min-width: $breakpoint-tablet) {
      height: 20rem;
    }
  }

  .Content {
    position: relative;
    z-index: 3;

    margin-top: -10rem;
    @media (min-width: $breakpoint-tablet) {
      margin-top: -20rem;
    }
  }

  h1 {
    font-family: $header-font;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
    margin: 1rem 0 0;
    color: #dce9db;
    // position: absolute;
    // margin: auto;
    // width: 100%;
  }

  @media (min-width: $breakpoint-tablet) {
  }
  @media (min-width: $breakpoint-desktop) {
  }
}
