@import "./shared";

.App {
  font-family: $font;
  overflow: hidden; // For the "preview" banner
  position: relative;

  // background-color: #353338;
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background: linear-gradient(180deg, #131a00 0%, #020202 60%, #020202 100%);
}

.Page {
  // background: linear-gradient(90deg, rgba(2,0,46,1) 0%, rgba(9,9,181,1) 50%, rgba(0,12,255,1) 100%);
}

$header-top: 0; //12rem;

/* Rhythm */

h1,
h2,
h3 {
  font-family: $header-font;
  margin: 0;
}

h1 {
  text-align: center;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.6;
  margin: 0;
  @media (min-width: $breakpoint-tablet) {
    font-size: 2.2rem;
  }
}

p {
  margin: 0;
  font-size: 1em;
  font-family: inherit;
  padding: 0 0.5em;
  @media (min-width: $breakpoint-tablet) {
    // font-size: 1.3em;
    padding: 0;
  }
}

p + p {
  margin-top: 1em;
}

html {
  line-height: 1.4;
  background2: #666;
  font-size: 16px;
}

a {
  color: $text;
  &:hover {
    color: $link;
  }
}

/* */

.App {
  // background: white;
  // width: 100%;
  // color: #333;
}
